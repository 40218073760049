@import "@fontsource/gothic-a1";

@font-face {
  font-family: "Ryo Gothic";
  src: local("Ryo Gothic"), url(../assets/font/RyoGothic.otf) format("opentype");
}
@font-face {
  font-family: "Neue Haas";
  src: local("Neue Haas"), url(../assets/font/NeueHaas.otf) format("opentype");
}

.ryogothic {
  font-family: "Ryo Gothic";
}

.neuehaas {
  font-family: "Neue Haas";
}

.body-wrapper {
  border: solid 1px #8e9291;
  border-top: 0;
  border-bottom: 0;
}
.toaster {
  max-width: 95%;
  width: max-content;
  word-break: break-all;
}
.loader {
  z-index: 1000;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  gap: 20px;
  display: grid;
}

.button {
  align-items: center;
  background-color: #8e9291;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
  width: 100%;
  height: 46px;
  margin-top: 20px;
  color: #ffffff;
  font-family: "Ryo Gothic";
  font-weight: normal;
  font-size: 20px;
}
.button:hover {
  scale: 1.01;
}
.button:disabled {
  scale: 1;
}
.point-wrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -20px;
  width: 100%;
  background-color: #fff;
  padding: 10px 30px;
}
.carnft-font {
  font-family: "Gothic A1";
  font-weight: 900;
  -webkit-text-stroke-width: 0.2px;
}
.carnft-font.panel-title {
  color: #3642ab;
  font-size: 24px;
  letter-spacing: 0.72px;
  -webkit-text-stroke-width: 0.2px;
  margin-top: 5px;
}

.carnft-font.title {
  color: #000;
  font-size: 14px;
  letter-spacing: 0.84px;
}

.carnft-font.description {
  color: #000;
  font-size: 14px;
  letter-spacing: 0.84px;
  min-width: 100px;
}

.carnft-font.description.privacy-policy {
  font-size: 16px;
}

.vc-img {
  background-position: center;
  background-repeat: no-repeat;
  height: 380px;
}

.line {
  font-family: "Gothic A1";
  font-weight: 900;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #06c755;
  color: #ffffff;
  width: 150px;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  font-size: 16px;

  justify-content: space-around;
  padding: 2px 5px;
}

.line:hover {
  opacity: 0.8;
}

.text-stroke-custom {
  -webkit-text-stroke-width: thin;
}
@media screen and (min-width: 500px) {
  .toaster {
    max-width: 837px;
    word-break: break-all;
  }
}
@media screen and (max-width: 500px) {
  .body-wrapper {
    border: 0;
  }
}
@media screen and (max-width: 415px) {
  .carnft-font.panel-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 365px) {
  .carnft-font.description {
    min-width: 70px;
  }
}
@media screen and (min-width: 365px) {
  .text-info {
    font-size: 14px;
  }
}
@media screen and (min-width: 200px) and (max-width: 364px) {
  .text-info {
    font-size: 14px;
  }
}

@media screen and (min-width: 358px) {
  .panel-title {
    font-size: 18px;
    margin-left: 2rem;
  }
}
@media screen and (min-width: 200px) and (max-width: 357px) {
  .panel-title {
    font-size: 16px;
    margin-left: 2.5rem;
    margin-top: 3px;
  }
}

