
@font-face {
  font-family: "Ryo Gothic";
  src: local("Ryo Gothic"), url(../assets/font/RyoGothic.otf) format("opentype");
}
@font-face {
  font-family: "Neue Haas";
  src: local("Neue Haas"), url(../assets/font/NeueHaas.otf) format("opentype");
}

.react-datepicker-popper{
    z-index: 1000;
    box-shadow: 0px 0px 30px darkgray;
}
.react-datepicker__input-container input{
    border: solid 1px #8E9291;
    border-radius: 4px!important;
    height: 38px;
    width: 100%;
}
.react-datepicker__month-container {
    width: 100% !important;
    font-size: 18px;
    border: solid 1px #eee;
  }
  .react-datepicker__navigation-icon {
    left: 1.5px;
    top: 2px;
  }
  
  .react-datepicker__navigation-icon--previous::before {
    right: -5px;
  }
  
  .react-datepicker__navigation-icon::before {
    border-color: #5c6470;
    border-width: 2px 2px 0 0;
  }
  .react-datepicker__day--outside-month {
    color: #22252a59; /* to add disabled feeling */
  }
  
  .react-datepicker__current-month {
    font-size: 18px !important;
    margin-top: 8px;
  }
  
  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white !important;
    border: none !important;
    margin: 0 10px;
  }
  
  .react-datepicker .react-datepicker__day-name,
  .react-datepicker .react-datepicker__day {
    text-align: center;
    margin: 1px;
    padding: 10px;
    color: #211111;
    width: 47px;
    font-family: "Ryo Gothic";
    font-weight: normal;
  }
  
  .react-datepicker .react-datepicker__day-names,
  .react-datepicker .react-datepicker__week {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
  
  .react-datepicker .react-datepicker__month {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
  
  .react-datepicker--time-only,
  .react-datepicker__time-box,
  .react-datepicker__time-container {
    width: 100% !important;
  }
  
  .react-datepicker .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker {
    color: white;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
    border-radius: 4px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #014598 !important;
    color: #fff !important;
  }
  .react-datepicker__navigation {
    margin: 10px;
    border: 1px solid #c7cbd1;
    border-radius: 4px;
  }
  
  .react-datepicker__aria-live {
    display: none;
  }
  
  .react-datepicker-time__header {
    font-size: 20px !important;
  }
  
  .react-datepicker {
    background-color: #ffffff !important;
    border: none !important;
    box-shadow: none !important;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #cce3ff;
    color: #2e3238;
  }
  .react-datepicker__day--in-selecting-range:hover {
    background-color: #014598;
    color: #fff;
    font-weight: bold;
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: #cce3ff;
    color: #2e3238;
  }
  