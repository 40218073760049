.slider-control-bottomcenter button{
    background: #404040 !important;
    border-radius: 50%;
    width: 8px !important;
    height: 8px !important;
    margin: 8px;
 }
 
 .slider-control-bottomcenter svg{
   display: none !important;
 }
 .slider-control-bottomcenter li{
   margin-right: 5px;
 }